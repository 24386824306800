.c-sidebar-brand > * > * {
  padding: 0 !important;
  margin: 0 !important;
}

.customModal {
  background-color: hsla(0, 0%, 0%, 0.6) !important;
}

.customModal .modal-dialog {
  min-width: 60%;
  max-width: 90%;
}

.customModal .modal-body {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.customModal .modal-body * {
  max-width: 100%;
  flex: none;
}

.customModal textarea#kubeconfigWindows,.customModal textarea#kubeconfigLinux {
  resize: none;
  min-height: 200px;
  font-size: 1.2em;
  overflow-y: hidden;
  background-color: var(--clr-dark-bg-grey-hover);
  color: var(--clr-menu-hover-font);
}

.customModal label {
  font-size: 1.1em;
  font-weight: bold;
}

.userNotAcceptedPage {
  height: 100vh;
  width: 100vw;
  transform: translateY(-25%);
}

.apiKey {
  display: flex;
  justify-content: space-between;
}

.apiKeyText {
  align-items: center;
  width: 60%;
  gap: 0.5em;
}

.apiKey .btn {
  background-color: var(--clr-dark-bg-grey) !important;
  color: var(--light);
  border-radius: 0.25em;
  font-weight: bold;
}

.apiKey .btn.disabled {
  pointer-events: none;
  cursor: none;
}

.apiKey .btn i {
  padding-left: 0.5em;
}

.apiKey .btn .success {
  color: var(--success);
}

.apiKey .btn .inprogress {
  color: var(--warning);
}

.apiKey .btn .error {
  color: var(--danger);
}

.apiKeyText input {
  background-color: var(--clr-menu-hover-font) !important;
}

#firebaseui_container form button {
  background-color:#87a9b7!important;
  font-weight: bold;
  border-radius: 5px;
}

#firebaseui_container form button:hover {
  box-shadow: 0px 0px 10px var(--dark);
}
