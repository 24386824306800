.terms p,
.terms li {
  font-size: 12px;
}

.terms {
  display: block !important;
  padding: 0 !important;
}

.termsModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.termsModal .modal-dialog {
  max-width: 90vw;
}

.termsModal .modal-content {
  height: 100%;
  width: 100%;
}

.termsModal .modal-body {
  max-height: 80vh;
  width: 90vw;
  overflow-y: auto;
}
