body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* Default from Core-UI - START */
  --primary: hsl(246, 75%, 49%);
  --secondary: hsl(216, 11%, 83%);
  --success: hsl(140, 60%, 45%);
  --info: hsl(210, 100%, 60%);
  --warning: hsl(41, 95%, 53%);
  --danger: hsl(0, 74%, 61%);
  --light: hsl(210, 11%, 93%);
  --dark: hsl(218, 14%, 45%);
  /* Default from Core-UI - END */

  /* menu grey background options */
  --clr-dark-bg-grey: hsl(220, 27%, 26%);
  --clr-dark-bg-grey-hover: hsl(220, 27%, 10%);
  --clr-light-bg-grey: hsl(218, 25%, 35%);

  --clr-breadcrumb-selected: hsl(218, 11%, 59%);

  /* light grey container backgrounds */
  --clr-content-bg-lightgrey: var(--light);

  /* contract option -> minimised logo color */
  --clr-logo-orange: var(--warning);

  /* font color for menu items on grey bg, card backgrounds */
  --clr-menu-font: hsla(0, 0%, 100%, 0.8);

  /* font color for dark backgrounds on hover, font color for blue buttons */
  --clr-menu-hover-font: hsl(0, 0%, 100%);

  /* Font color for links, button color, menu hover bg color */
  --clr-blue: var(--primary);

  /* Used for card headers */
  --clr-bg-green: hsl(111, 40%, 95%);
}
