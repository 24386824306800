.yamlTextArea {
  width: 100vm;
  max-width: none !important;
  resize: none;
  background-color: black !important;
  color: white !important;
}

.yamlErrorText {
  max-width: 100vmin !important;
  margin-bottom: 0;
}

.valuesForm * {
  max-width: unset !important;
  width: auto;
}

.valuesForm .row label {
  font-weight: unset;
}

.mrk__config__tabs a.active.nav-link{
  pointer-events: none;
} 
.monaco-editor .cursors-layer{
  position: relative;
}