.spinner {
  width: 100%;
  text-align: center;
  padding: 1rem;
}

#newClusterName {
  margin-top: 1em;
}

.bs-stepper-content .content.active {
  margin-bottom: 1em;
}

.bs-stepper-content .sizeLabel {
  padding: 10px 0;
  font-weight: bold;
}

.deleteAlert {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.deleteAlert .alert-heading {
  font-weight: bold;
}

.deleteAlert .footer {
  text-align: center;
}

#featuresDiv form .form-group div {
  text-align: end;
  position: absolute;
  float: right;
  max-width: 95%;
  transform: translateY(-0.3em);
}

.selectedListClass {
  width: 60%;
  max-width: 500px;
  margin: 0 auto;
}

.featuresGrid .marketplaceMainTabs.tab-content {
  padding-top: 2em;
}

.featuresGrid .marketplaceMainTabs.tab-content .tab-pane.active,
.clustersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 1rem;
}
.featuresGrid .marketplaceMainTabs.tab-content .tab-pane.active .card.isSelected{
  opacity: 0.7;
}

.clustersGrid .disable {
  opacity: 0.5;
}
.clustersGrid .card.disable:hover{
  cursor:not-allowed;
  scale: 1;
}

.featuresGrid .card,
.clustersGrid .card {
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  transition-duration: 0.2s;
  background-color: #3c4b64;
  color: white;
}
.featuresGrid .card,
.featuresGrid .card .card-footer {
  background-color: rgb(60, 75, 100);
  color: white;
  display: flex;
  justify-content: space-around;
}

.featuresGrid .card .card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(60, 75, 100);
  color: white;
}

.featuresGrid .card:hover,
.clustersGrid .card:hover {
  scale: 1.1;
}

.featuresGrid .card.isSelected,
.clustersGrid .card.active {
  box-shadow: 0 0 4px 4px hsl(211, 100%, 50%);
}

.featuresGrid .card-header,
.clustersGrid .card-header {
  font-weight: bold;
  font-size: 1rem;
}

.clustersGrid .card-header,
.clustersGrid .card-footer {
  text-align: center;
  background-color: hsl(111, 40%, 95%);
}

.clustersGrid .card-body > * {
  padding: 2px;
}

.clustersGrid .card-footer h6 {
  font-weight: bold;
  font-size: 1.5rem;
}

.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.result-container .card-img-top {
  display: flex;
  justify-content: center;
  border: none;
}

.result-container .card-body {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.result-container .card-caption {
  color: hsl(0, 0%, 65%);
}

.result-container .card-footer {
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 1rem;
}

.overviewClass td {
  vertical-align: middle;
}

.overviewClass .expandIcon .fa {
  color: hsl(0, 0%, 95%);
  background-color: hsl(0, 0%, 0%);
  width: 14px;
}
.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 5px;
  text-align: center;
  width: 20%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.active-content {
  display: block;
}

.consoleModal {
  width: fit-content;
  height: fit-content;
  left: 20%;
  position: absolute;
  overflow: hidden;
}

.consoleModal .modal-dialog {
  max-width: fit-content;
}

.consoleModal .modal-body {
  padding: 0.25rem;
}

.consoleModal .sizing {
  position: absolute;
  transform: translateY(-0.4em);
  right: 3em;
}

.consoleModal .sizing:hover {
  color: var(--info);
}

@media (max-width: 320px) {
  .stepperHeader {
    display: block;
  }
}

@media (max-width: 400px) {
  .bs-stepper .stepperLine {
    -ms-flex-preferred-size: 20px;
    flex-basis: 10px;
  }
}

#specsDiv .dropdownExpiry {
  border: 1px solid #d8dbe0;
  border-radius: 5px;
}

#specsDiv .dropdownExpiry > * {
  width: 100%;
  color: #768192;
}

#specsDiv .dropdownExpiry .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#specsDiv .dropdownExpiry .dropdown-toggle::after {
  all: unset;
  content: "\f107";
  position: absolute;
  right: 6px;
  font-size: 12px;
  font-weight: bold;
}
.addonsTableWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.table .addonstable {
  border: solid 1px #cac9c8;
  text-align: center;
}
.overviewClass td,
.overviewClass th {
  text-align: center;
}

.success-message {
  display: block;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  color: #155724;
  background-color: #d4edda;
  border: 1px solid #c3e6cb;
  text-align: center;
  font-weight: bold;
}

.create-cluster-fields {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.create-cluster-form {
  width: 50%;
  align-items: center;
}
.create-cluster-form .form-group .row .col {
  width: 100%;
}
