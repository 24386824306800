.sign-in {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--clr-content-bg-lightgrey);
}

.sign-in::before {
  content: "";
  background-image: url("../../../assets/images/background.svg");
  background-size: cover;
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.sign-in .logo {
  background-size: cover;
  margin-bottom: 2em;
  width: 20em;
  height: 3em;
  transform: translateY(-50%);
}

.sign-in .card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.sign-in .card-footer .form-check-input {
  margin-top: 0.6rem;
}
.landing {
  background-color: #59B8A8;
  color: #044279;
  font-weight: bold;
  border-color: none;
}