.kntsCLICommands small {
  color: var(--warning);
}

.kntsCLICommands h6 {
  color: var(--clr-light-bg-grey);
}

.kntsCLICommands p {
  color: var(--light);
  padding: 0.5em 0;
  font-size: medium;
}

.kntsCLICommands p i {
  padding-right: 0.5em;
  font-size: small;
}

.kntsCLICommands {
  background-color: black;
}

.kntsCLICommands li {
  border: 1px solid var(--clr-dark-bg-grey-hover);
}

.copyButton {
  display: inline;
  padding-right: 10px !important;
}

.right {
  float: right;
  font-size: 12px;
  width: 50px;
}
